.calendar-container {
    margin-top: 20px;
    width: 900px;
    height: 800px;
    background: white;
    padding: 20px;
    border-radius: 20px;
}
.sign-in-header {
    justify-content: space-between;
}

.date-picker{
    width: auto;
    height: 25px;
}

.exit-btn{
    margin-right: 100px;
    cursor: pointer;
}
.exit-btn:active{
    transform: scale(0.8);
}

.arrow-btn{
    width:50px;
    cursor: pointer;
}

#forward-btn:active{
    transform: scale(0.8);
}
#back-btn:active{
    transform: scale(-0.8);
}
.month-header {
    display: flex;
}

.month-header h1{
    margin: 0px 10px 10px 10px;
}


.calendar-days{
    display: grid;
    min-height: 600px;
    padding: 10px;
    grid-template-columns: repeat(7,1fr);
    grid-template-rows: repeat(6,1fr);
    column-gap: 2px;
    row-gap: 2px;
    border: none;
    /* grid-template-rows: repeat(7, 30px); */
}

.calendar{
    border: solid black;
    background-color: skyblue;
    padding: 20px;
    border-radius: 20px;
    height: 700px;

}




.day-square-true-false{
    border: solid black;
    height: 100px;
    overflow-y: auto;
    background-color: white;
}
.day-square-true-true{
    background-color: rgba(255, 255, 0, 0.505);
    border: solid yellow;
    height: 100px;
    overflow-y: auto;
}
.completed-tasks{
    height: 100px;
    
}

ul{
    margin: 0px;
    padding: 0px;
}
h5{
    font-weight: bold;
    padding-bottom: 20px ;
}

.task-item{
    padding: 0px;
    padding-top: 20px;
    list-style: none;
    text-align: center;
    margin-bottom: 2px;
}
.dueGoal-item{
    position: relative;
    padding: 0px;
    list-style: none;
    text-align: center;
    margin-bottom: 2px;
    font-weight: bold;
}
.week-days-header{
    display: grid;
    text-align: center;
    grid-template-columns: repeat(7, 1fr);
}
.week-days-header p{
    background-color: bisque;
    font-weight: bold;
    padding: 10px;
    margin: 0px 2px 0px 2px;
    border-radius: 5px;
}


#sunday {
    background-color: rgba(255, 0, 0, 0.618);
}
#monday {
    background-color: rgba(255, 166, 0, 0.597);
}
#tuesday {
    background-color: rgba(255, 255, 0, 0.65);
}
#wednesday {
    background-color: rgba(0, 128, 0, 0.498);
}
#thursday {
    background-color: rgba(0, 0, 255, 0.519);
}
#friday {
    background-color: rgba(76, 0, 130, 0.474);
}
#saturday {
    background-color: rgba(238, 130, 238, 0.544);
}

@media screen and (max-width: 920px){
    .week-days-header{
        margin: 5px;
    }
    .date-picker{
        margin: 7px;
    }
    .exit-btn{
        margin: 7px;
    }
    h5{
        font-size: 15px;
    }
    h1{
        font-size: 25px;
    }
    .arrow-btn{
        width: 35px;
    }

    p{
        font-size: 10px;
        padding: 0px;
    }
    .calendar-container{
        padding: 0px;
    }
    .calendar{
        margin: 0px;
        padding: 0px;
    }
    .calendar-days{
        margin: 0px;
        column-gap: 0px;
        row-gap: 0px;
    }
}

