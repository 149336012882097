

body {
  font-family: 'Rubik', sans-serif;

}

.heading {
  display: grid;
  grid-template-columns: 2fr 1fr;

}
#calendar-btn {
  cursor: pointer;
  width:75px;
  border: none;
  background: none;
  position: absolute;
  font-size: 30px;
  padding: 5px;
}
#calendar-btn:active {
  transform: scale(0.8);
}

.offline-warning {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'empty warning' ;
  height: 50px;

  /* border: solid black; */
}
.offline-warning h5 {
  border: solid black;

  font-weight: bold;
  font-family: 'Rubik', sans-serif;
  background-color: rgba(255, 255, 0, 0.597);
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  grid-column: 2;
  /* grid-area: 'warning'; */
}

.offline-warning h5 p {
  font-size: 15PX;
}
@media screen and (max-width: 920px) {
  .offline-warning {
    display: flex;
    width: 300px;
    align-self: center;
    margin-left: 20% ;
  }
  .offline-warning h5 {
    font-size: 15px;
  }
  .offline-warning h5 p {
    font-size: 10px;
  }
}

.add-field {
  padding-bottom: 10px;
}

.App {
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  padding: 0px;
  margin: 0px;
}

#app-container {
  display: flex;
  width: 80%;
}

@media screen and (max-width: 920px) {
  #app-container {
    width: 100%;
  }
}

#sign-in-btn {
  align-self: flex-end;
  margin: 20px;
  width: 80px;
  grid-column: 3;

  background: steelblue;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 5px;
  width: 100px
}



.sign-in-window {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.611);
  display: flex;
  justify-content: space-around;
}

.sign-in-header {
  display: flex;
  justify-content: space-between;
}

.sign-in-header img {
  object-fit: cover;
}
.container {
  /* display: flex; */
  border: 2px solid steelblue;
  /* margin-left: 30px;; */
  /* max-width: 500px; */
  min-height: 500px;
  max-width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
}

.minimized {
  color: blue;
  max-width: 400px;
  border: solid black 2px;
  /* align-self:flex-start */
}

.edit-task-title {
  width: 1000%;
}

.edit-done-btn {
  color: black; 
  font-weight: bold;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
}
@media screen and (max-width: 920px){
.edit-done-btn {
  font-size: 80%;
}
}

.edit-task-btns {
  font-size: 15px;
  min-height: 60px;
  border: none;
  border-radius: 10px;
  margin: 20px 0px 20px 10px;
}
@media(max-width:500px){
  .edit-task-btns{
    font-size: 12px;
    margin-left: 0px;
  }
}

.edit-goal-title {
  width: 1000%;
  margin-right: 2px;
}
@media(max-width:500px){
  .edit-goal-title {
    width: 40vw;
  }
}
.edit-due-date{
  width: 150px;
}
.goal-reorder {
  cursor: pointer;
  padding: 0px;
  margin: 1px;
  font-size:30px;
  margin-bottom: 7px;
  border: none;
  }

.goal-reorder:active {
  transform: scale(.95);
}
/* @media(min-width:500){
  .goal-reorder{
    font-size: 40px;
  }
} */
.task-reorder {
  cursor: pointer;
  padding: 0;
  margin: 1px;

  font-size: 20px;
  border: none;
}


.small-btn {
  background-color: white;
  border: none;
}
.btn {
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin: 20px 0px 10px 20px;
  /* display: flex;
  justify-self: center; */
}

.trash-bin{
  width:40px;
   height:auto;
    margin:10px;

}
.trash-bin:active{
 transform: scale(0.8);
}

.completed-btn {
  margin-right:5px; 
  color: black;
  background-color:yellow; 
  border-radius: 5px;
  border: 2px black solid;
  font-weight: 600
}
.completed-btn:active {
  transform: scale(.95);
}

.completed-reorder {
  padding: 0px;
  margin: 0px;
  font-size:27px;
  border: none;
  background-color:transparent;
}
.completed-reorder:active {
  transform: scale(.95);
}

.btn:active {
  transform: scale(.95);
}

.task {
  background: rgba(51, 170, 51, .1);

}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
  background: #f5f5f5;
  padding-left: 10px;

  /* padding-bottom: 0px; */
  margin: 5px;
}

.task-list {
  display: flex;
  flex-direction: column;

}

.task-list-item {
  display: flex;
  font-weight: bold;

}
.checkbox-subgoal{
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.checkbox-goal {
  width: 20px;
  height: auto;
  margin-right: 2px;
  margin-bottom: 10px;

}

.done-true {
  list-style-type: none;
  cursor: pointer;
  

}
.done-false {
  list-style-type: none;
  cursor: pointer;
  
}

#drop-down {
  background-color: green;
  color: white;
}

.flex {
  display: flex;
}

.detail {
  padding:1px;
  margin: 0px;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.non-clickable {
  pointer-events: none;
}

.goal-form {
  display: flex;
  flex-direction: column;
  align-items:stretch;
  /* border: 4px solid black; */
  min-width: 45%;

}

.header {
  display: flex;
  justify-content: space-between;
  /* border: solid black 2px; */
  align-items:center;

}

.min-max-btn-true {
  outline: 3px solid orange;
  border:solid 3px orange;
  background-color: yellow;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  
}
.min-max-btn-false {
  border:solid 2px black;
  color: black;
  font-weight: bold;
  border-radius: 5px;
}
.min-max-btn-Goals {
  border:solid 2px black;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  
}

.plus-btn {
  padding: 4px 8px;
  /* background: black; */
  margin-left: 4px;
  border: black solid 1px;
  border-radius: 5px;
  font-family: inherit;
  /* font-weight: bold; */
  color: white;
  font-size: 8px;
  cursor: pointer;
}

.goal-form-container {
  display: flex;
  justify-content:space-around;
  align-items: flex-start;

}

.hue-picker {
  cursor: pointer;
  width:initial;
}