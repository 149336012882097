
.login-container {
    margin-top: 20px;
    width: 500px;
    height: 500px;
    background: white;
    padding: 20px;
    border-radius: 20px;
}

@media screen and (max-width: 920px){
    .login-container{
        width: 90%
    }
}


.login-form {
    display: flex;
    flex-direction: column;
}

#create-account {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    color: blue;

}

#create-account:hover {
    transition: ease-in;
    color:blueviolet;
}
#forgot-password {
    cursor: pointer;
    color: blue;

}

#forgot-password:hover {
    transition: ease-in;
    color:blueviolet;
}

.sign-in-container{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}
.sign-in-container p{
    align-self: flex-end;
    justify-self: end;
    font-weight: bold;
    /* border: solid black; */
}
.sign-in-btn {
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    background: green;
    border:none;
    border-radius: 10px;
    padding: 10px 25px;
    width: 40%;
    
}

@media screen and (max-width:920px){
    .sign-in-btn {
        padding: 0px;

    }
    .google-sign-in-btn{
        padding: 5px 12px;
    }

}
.send-reset-btn {
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    background: darkorange;
    border:none;
    border-radius: 10px;
    padding: 10px 25px;
    width: 200px;
    
}

.google-sign-in-btn {
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    background: #4285F4;
    border:none;
    border-radius: 10px;
    width: 40%;
    
}
.back-btn {
    border: none;    
    background: none;
    font-size: 25px;
}
.back-btn:active {
    transform: scale(0.8);
}

.sign-up-error {
    padding: 0px;
    margin: 0px;
    font-size: small;
    color: red;
}
.create-account-btn-a {
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    background: green;
    border:none;
    border-radius: 10px;
    padding: 10px 25px;
}
.create-account-btn-b {
    cursor: not-allowed;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    background: gray;
    border:none;
    border-radius: 10px;
    padding: 10px 25px;
}

.btn-container {
    display: flex;
    justify-content: space-around;
}